import { AplicationParams } from "../types/app";

/**
 * LEAN: Removes padding from main container
 * BACKGROUND: Adds a default background to the main container
 */
export enum ViewParam {
  LEAN = "lean",
  BACKGROUND = "background",
}

export const appParams = (
  params: any,
): {
  [key: string]: any | undefined;
  appid: string;
  objectId: string;
  view: ViewParam[];
  hasViewParam: (param: ViewParam) => boolean;
} => {
  const viewParams = (params.view?.split(",") ?? []) as ViewParam[];
  return {
    ...params,
    [AplicationParams.APPID]: params.appid as string,
    [AplicationParams.OBJECTID]: params.objectId as string,
    [AplicationParams.VIEW]: viewParams,
    hasViewParam: (param: ViewParam) => viewParams.includes(param),
  };
};
