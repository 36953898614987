import { Box, BoxProps } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { Navigate, Route, Routes } from "react-router-dom";
import { useBookingApi } from "./api";
import { BuildingId } from "./api/types";
import { BookingDetailPage, BookingPage, DynamicPage, PurchasePage } from "./components/app";
import { InvoiceConfirmationPage } from "./components/app/InvoiceConfirmationPage";
import { Loading } from "./components/common";
import { env } from "./env";
import {
  AppSettingsProvider,
  RoomBookingProvider,
  useDownBreakpoint,
  useInitialQueryParams,
  UserFormProvider,
} from "./hooks";
import { PATHS } from "./paths";
import { CustomThemeProvider } from "./theme/CustomThemeProvider";
import { appParams, CustomTheme, ViewParam } from "./utils";

const DEV_APP_ID = "1";

const ReservationAppContainer = ({ sx = {}, ...props }: BoxProps<"main">) => (
  <Box
    component="main"
    padding={3}
    sx={{
      flexGrow: 1,
      bgcolor: "background.default",
      height: 1,
      width: 1,
      position: "relative",
      ...sx,
    }}
    {...props}
  />
);

const ReservationAppForClient = ({ buildingId }: { buildingId: BuildingId }) => {
  const { data: appSettings, isLoading } = useQuery(useBookingApi().getAppSettings(buildingId));
  const isMobile = useDownBreakpoint("lg");

  if (isLoading) return <Loading sx={{ height: 1 }} />;
  if (appSettings === undefined) throw new Error("App settings not available.");

  const customTheme: CustomTheme = {
    primaryColor: appSettings.common?.primaryColor,
  };

  Sentry.setContext("client", {
    title: "Hotel Reservation App",
    ...appSettings,
  });

  return (
    <CustomThemeProvider theme={customTheme}>
      <AppSettingsProvider defaultSettings={appSettings}>
        <RoomBookingProvider>
          <UserFormProvider>
            <Routes>
              <Route path={PATHS.BOOK} element={<BookingPage compact={isMobile} />} />
              <Route path={PATHS.PURCHASE} element={<PurchasePage compact={isMobile} />} />
              <Route path={PATHS.DYNAMIC_CONTENT} element={<DynamicPage compact={isMobile} />} />
              <Route path="*" element={<Navigate to={PATHS.BOOK} />} />
              <Route path={PATHS.INVOICE_CONFIRMATION} element={<InvoiceConfirmationPage compact={isMobile} />} />
              <Route path={PATHS.RESERVATION_STATUS} element={<BookingDetailPage compact={isMobile} />} />
            </Routes>
          </UserFormProvider>
        </RoomBookingProvider>
      </AppSettingsProvider>
    </CustomThemeProvider>
  );
};

export const ReservationApp = () => {
  const { appid, objectId, hasViewParam } = appParams(useInitialQueryParams());
  //NOTE: viewParam is not fully implemented yet, it doesnt affect the PaymentConfirmationPage so its not globally used

  // Fallback to DEV_APP_ID if appId is not set (only for DEV environment)!
  const fallbackAppId = env.IS_DEV ? DEV_APP_ID : undefined;
  const hotelId = appid ?? fallbackAppId;
  const buildingId = objectId ?? fallbackAppId;
  if (hotelId === undefined) return null;

  return (
    <ReservationAppContainer padding={hasViewParam(ViewParam.LEAN) ? 0 : 3}>
      <ReservationAppForClient buildingId={buildingId} />
    </ReservationAppContainer>
  );
};
