import { ModalProps } from "@mui/base";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import VC from "vanilla-calendar-pro";
import { FormatDateString, IOptions } from "vanilla-calendar-pro/types";

import "vanilla-calendar-pro/build/vanilla-calendar.min.css";

export interface disabledDateRange {
  startDate: string;
  endDate: string;
}

const getDisabledDates = (ranges: disabledDateRange[] | []) => {
  const disabledDates: string[] = [];

  ranges.forEach((range) => {
    let currentDate = new Date(range.startDate);
    const endDate = new Date(range.endDate);

    endDate.setDate(endDate.getDate() - 1);

    while (currentDate <= endDate) {
      disabledDates.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  });

  return disabledDates;
};

interface VanillaCalendarProps extends HTMLAttributes<HTMLDivElement> {
  config?: IOptions;
}

const VanillaCalendar = ({ config, ...attributes }: VanillaCalendarProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [calendar, setCalendar] = useState<VC | null>(null);

  useEffect(() => {
    if (!ref.current) return;
    setCalendar(new VC(ref.current, config));
  }, [ref, config]);

  useEffect(() => {
    if (!calendar) return;
    calendar.init();
  }, [calendar]);

  return <div {...attributes} ref={ref}></div>;
};

interface RoomDetailAvailabilityModalProps extends Omit<ModalProps, "children"> {
  compact?: boolean;
  disabledDates?: disabledDateRange[];
}

export const RoomDetailAvailabilityModal = ({
  compact,
  disabledDates = [],
  ...props
}: RoomDetailAvailabilityModalProps) => (
  <Modal disableAutoFocus {...props}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: compact ? 0.9 : 650,
        height: compact ? 0.9 : "auto",
        maxHeight: "100vh",
        boxShadow: 0,
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <Stack alignItems="center" gap={{ sm: 1, md: 3 }} sx={{ padding: compact ? 2 : 4 }}>
        <Box mt={4}>
          <Typography variant="h5">Ověření dostupnosti pokoje</Typography>
        </Box>
        <IconButton
          color="primary"
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
          onClick={() => props.onClose?.({}, "escapeKeyDown")}
        >
          <HighlightOffIcon />
        </IconButton>
        <VanillaCalendar
          config={{
            type: "multiple",
            settings: {
              lang: "cs",
              visibility: {
                theme: "light",
                daysOutside: false,
              },
              range: {
                disabled: getDisabledDates(disabledDates) as FormatDateString[],
                disablePast: true,
              },
            },
          }}
        />
        <Stack>
          <Typography variant="body1" fontWeight="bold" sx={{ textAlign: "center" }}>
            Legenda
          </Typography>
          <Stack direction={{ xs: "column", sm: "row" }} gap={2} mt={1}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Box sx={{ height: "10px", width: "10px", bgcolor: "cornflowerblue", borderRadius: "100%" }} />
              <Typography variant="body1">Dnes</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <Box sx={{ height: "10px", width: "10px", bgcolor: "#53f153" }} />
              <Typography variant="body1">Volný termín</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <Box sx={{ height: "10px", width: "10px", bgcolor: "#ff5b5b" }} />
              <Typography variant="body1">Nedostupný termín</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  </Modal>
);
