export type HotelId = string;
export type BuildingId = string;
export type RoomId = string;

export type PaymentMethodType = "online" | "bank" | "cash";

export interface ApiResponse<T = any[]> {
  success: boolean;
  statusCode?: number;
  processName?: string;
  processDate?: string;
  processDuration?: string;
  results: T;
  message?: string;
  errors?: [];
}

export interface ImageMetadata {
  url: string;
  alt: string;
}

interface ImageCategories {
  all: ImageMetadata[];
  head: ImageMetadata;
  other: ImageMetadata[];
}

export type AtributeType = "Integer" | "String" | "Boolean";

export interface Attribute<Key extends any = any> {
  key: Key;
  locale: string;
  type: AtributeType;
  value: string;
}

export interface HotelDto {
  id: HotelId;
  title: string;
}

interface MarketingPageLink {
  title: string;
  url: string;
}

export interface AppSettingsDto {
  objectInfo: HotelDto;
  appSettings: {
    appUrl: string;
    marketingPages: {
      reservationConditions: MarketingPageLink;
      marketingNewsletter: MarketingPageLink;
    };
    primaryColor: string;
    defaultStartDate: Date;
    defaultEndDate: Date;
    maxDate?: Date;
  };
}

export interface RoomDto {
  id: RoomId;
  title: string;
  images: ImageCategories;
  attributes?: Attribute[];
  facilities?: Attribute[];
  occupancyMin: number;
  occupancyMax: number;
  description?: string;
  prices: {
    price_value_tax: number;
  };
  unitPrice?: {
    price_value_tax: number;
  };
  availability: {
    code: 303;
    isAvailable: boolean;
    message: string;
  };
}

export interface SettingsDto {
  objectId: HotelId;
  startDate: string;
  endDate: string;
  returnUrl: string;
}
export interface BookingBaseDto {
  unitId: RoomId;
  price: number;
}

export interface BookingValidationResponse {
  bookings: RoomDto[];
  valid: boolean;
  problemItems: {
    objectId: HotelId;
    unitId: RoomId;
    type: "error" | "warning";
    message: string;
  }[];
}

interface PaymentInfo {
  transactionId: number;
  gatewayUrl: string;
  orderId: number;
}

export interface BookingCreatedResponse {
  bookingCode: string;
  paymentInfo: PaymentInfo;
}

export enum PaymentStatus {
  CREATED = "created",
  PENDING = "waiting",
  SUCCESS = "paid",
  FAILURE = "canceled",
}

export interface OrderStatusReponse {
  paymentInfo: PaymentInfo & { paymentMethod: PaymentMethodType };
  bookingData: any;
  statusData: {
    bookingCode: string;
    status: PaymentStatus;
    statusId: number;
    statusTitle: string;
    lastUpdate: Date;
  };
}
